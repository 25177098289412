import { interpolateBuPu, interpolateYlOrBr } from "d3-scale-chromatic";

// using to convert all color data in settings to hexadecmial format
function rgbToHex(rgb) {
  const [r, g, b] = rgb.match(/\d+/g).map(Number);

  // Calculate hex values
  const redHex = r * 256 * 256;
  const greenHex = g * 256;
  const blueHex = b;

  const hex = redHex + greenHex + blueHex;

  // Convert to hexadecimal and format
  return `#${(hex + 0x1000000)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}

function getNivoColors(num, scheme) {
  const colors = [];
  for (let i = 0; i <= num; i += 1) {
    const color = scheme(i / num);
    colors.push(rgbToHex(color));
  }
  return colors;
}

// Get 9 colors in the blue-purple or yellow-orange-brown scheme
const bluePurpleColors = getNivoColors(8, interpolateBuPu);
const yellowOrangeBrown = getNivoColors(8, interpolateYlOrBr);

export function addColorsToBarGraphData(data, statuses) {
  const colorMap = {};
  statuses.forEach((status, index) => {
    colorMap[status.name] =
      status.graphColor || bluePurpleColors[index % bluePurpleColors.length];
  });

  return data.map(item => {
    const colors = {};
    Object.keys(item).forEach(key => {
      if (colorMap[key]) {
        colors[key] = colorMap[key];
      }
    });
    return { ...item, colors };
  });
}

export function addPhaseColorsToPieData(data, statuses) {
  const colorMap = {};
  statuses.forEach((status, index) => {
    colorMap[status.name] =
      status.graphColor || bluePurpleColors[index % bluePurpleColors.length];
  });

  return data.map((item, index) => {
    const graphColor =
      colorMap[item.id] || bluePurpleColors[index % bluePurpleColors.length];

    return { ...item, graphColor };
  });
}

export function addCategoryColorsToPieData(data, categories) {
  const colorMap = {};
  categories.forEach((category, index) => {
    colorMap[category.name] =
      category.graphColor ||
      yellowOrangeBrown[index % yellowOrangeBrown.length];
  });

  return data.map((item, index) => {
    const graphColor =
      colorMap[item.id] || yellowOrangeBrown[index % yellowOrangeBrown.length];

    return { ...item, graphColor };
  });
}
