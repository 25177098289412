import { API } from "aws-amplify";

function formatStatus(status) {
  const { name, color, closed } = status;
  const text = closed ? `${name} (Closed)` : name;
  return {
    key: name,
    text,
    value: name,
    label: { color, empty: true, circular: true }
  };
}

async function get(customerId) {
  const settings = await API.get(
    "audit-apis",
    `/customers/${customerId}/dashboard/`,
    {}
  );
  const { data } = settings;
  return data.map(formatStatus);
}

module.exports = { get };
