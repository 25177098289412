import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import { addColorsToBarGraphData } from "./custom-graph-colors";

const StackedBarByAudit = ({
  byAuditAndStatus,
  statusKeys,
  handleChartClick,
  recommendationStatuses,
  graphTextColor
}) => {
  const newData = addColorsToBarGraphData(
    byAuditAndStatus,
    recommendationStatuses
  );

  const defaultColorScheme = { scheme: "blue_purple" };
  const hasCustomColors = newData.some(
    data => data.colors && Object.keys(data.colors).length > 0
  );
  const colorSetting = hasCustomColors
    ? ({ id, data }) => data.colors[id]
    : defaultColorScheme;
  return (
    <React.Fragment>
      <ResponsiveBar
        data={newData}
        labelTextColor={graphTextColor || "black"}
        keys={statusKeys}
        layout="horizontal"
        indexBy="auditName"
        margin={{ top: 10, right: 170, bottom: 50, left: 300 }}
        padding={0.2}
        colors={colorSetting}
        borderColor={{ theme: "background" }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        animate
        motionStiffness={90}
        motionDamping={15}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "# Recommendations",
          legendPosition: "middle",
          legendOffset: 40
        }}
        enableGridY={false}
        defs={[
          {
            id: "lines-pattern",
            type: "patternLines",
            spacing: 10,
            rotation: -110,
            lineWidth: 1,
            background: "inherit",
            color: "#ccc"
          },
          {
            id: "dots-pattern",
            type: "patternDots",
            size: 2,
            padding: 4,
            stagger: false,
            background: "inherit",
            color: "#ccc"
          }
        ]}
        fill={[
          {
            match: {
              id: "Not Started"
            },
            id: "lines-pattern"
          },
          {
            match: {
              id: "Implemented"
            },
            id: "dots-pattern"
          }
        ]}
        axisLeft={{
          renderTick: d => {
            const { opacity, textAnchor, textBaseline, value = "", x, y } = d;
            const trimValue =
              value.length > 55 ? `${value.slice(0, 55)}...` : value;
            return (
              <g transform={`translate(${x},${y})`} style={{ opacity }}>
                <line
                  x1="0"
                  x2="-5"
                  y1="0"
                  y2="0"
                  style={{
                    stroke: "rgb(119, 119, 119)",
                    strokeWidth: 1
                  }}
                />
                <text
                  alignmentBaseline={textBaseline}
                  textAnchor={textAnchor}
                  transform="translate(-10, 0)"
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: 11,
                    fill: "rgb(51, 51, 51)"
                  }}
                >
                  {trimValue}
                </text>
              </g>
            );
          }
        }}
        onClick={e => handleChartClick(e, "status", "auditName")}
      />
    </React.Fragment>
  );
};

StackedBarByAudit.propTypes = {
  byAuditAndStatus: PropTypes.arrayOf(
    PropTypes.shape({
      auditName: PropTypes.string.isRequired
    })
  ).isRequired,
  recommendationStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  statusKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChartClick: PropTypes.func.isRequired,
  graphTextColor: PropTypes.string
};

StackedBarByAudit.defaultProps = {
  graphTextColor: null
};

export default StackedBarByAudit;
