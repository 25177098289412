import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

const RecommendationRow = ({
  recommendation,
  activeFilterPrimary,
  activeFilterSecondary
}) => {
  const {
    recommendationId,
    recommendationText,
    category,
    auditName,
    department,
    status,
    update,
    issueDate,
    auditURL
  } = recommendation;

  let dateString = "";
  if (issueDate && issueDate.length > 1) {
    const dateTime = new Date(issueDate);
    const dateMonth = dateTime.getMonth() + 1;
    const dateYear = dateTime.getFullYear();
    dateString = `${dateMonth}/${dateYear}`;
  }

  const hasAuditURL = auditURL && auditURL.length > 1;

  return (
    <React.Fragment>
      <Table.Row key={recommendationId}>
        <Table.Cell
          width="2"
          positive={
            activeFilterPrimary === "department" ||
            activeFilterSecondary === "department"
          }
        >
          {department}
        </Table.Cell>
        <Table.Cell width="3" style={{ maxWidth: "175px", overflow: "hidden" }}>
          {recommendationText}
        </Table.Cell>
        <Table.Cell width="3" style={{ maxWidth: "200px", overflow: "hidden" }}>
          {update}
        </Table.Cell>
        <Table.Cell
          width="1"
          positive={
            activeFilterPrimary === "status" ||
            activeFilterSecondary === "status"
          }
        >
          {status}
        </Table.Cell>
        <Table.Cell
          width="2"
          positive={
            activeFilterPrimary === "auditName" ||
            activeFilterSecondary === "auditName"
          }
        >
          {hasAuditURL && (
            <a href={auditURL} target="_blank" rel="noopener noreferrer">
              {auditName}
            </a>
          )}
          {!hasAuditURL && auditName}
          <br />
          {dateString}
        </Table.Cell>
        <Table.Cell
          width="2"
          positive={
            activeFilterPrimary === "category" ||
            activeFilterSecondary === "category"
          }
        >
          {category}
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

RecommendationRow.propTypes = {
  recommendation: PropTypes.shape({
    recommendationId: PropTypes.string.isRequired,
    recommendationText: PropTypes.string.isRequired
  }).isRequired,
  activeFilterPrimary: PropTypes.string,
  activeFilterSecondary: PropTypes.string
};

RecommendationRow.defaultProps = {
  activeFilterPrimary: null,
  activeFilterSecondary: null
};

export default RecommendationRow;
