import { Auth } from "aws-amplify";

async function getAccessToken() {
  try {
    const session = await Auth.currentSession();
    return session.accessToken.jwtToken;
  } catch (e) {
    return null;
  }
}

function getCustomerDomain() {
  const [domain] = window.location.hostname.split(".");
  return domain;
}

async function getAuthHeaders() {
  const accessToken = await getAccessToken();
  const customerDomain = getCustomerDomain();
  if (accessToken && customerDomain) {
    return { Authorization: `Bearer ${customerDomain}:${accessToken}` };
  }
  return null;
}

export default {
  API: {
    endpoints: [
      {
        name: "audit-apis",
        endpoint: process.env.AWS_AUDIT_APIS_ENDPOINT,
        custom_header: getAuthHeaders
      }
    ]
  }
};
