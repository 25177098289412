import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import { addColorsToBarGraphData } from "./custom-graph-colors";

const StackedBarByYear = ({
  byYearAndStatus,
  statusKeys,
  handleChartClick,
  recommendationStatuses,
  graphTextColor
}) => {
  const newData = addColorsToBarGraphData(
    byYearAndStatus,
    recommendationStatuses
  );

  const defaultColorScheme = { scheme: "blue_purple" };
  const hasCustomColors = newData.some(
    data => data.colors && Object.keys(data.colors).length > 0
  );
  const colorSetting = hasCustomColors
    ? ({ id, data }) => data.colors[id]
    : defaultColorScheme;

  return (
    <React.Fragment>
      <ResponsiveBar
        data={newData}
        labelTextColor={graphTextColor || "black"}
        keys={statusKeys}
        layout="vertical"
        indexBy="year"
        margin={{ top: 50, right: 170, bottom: 50, left: 60 }}
        padding={0.2}
        colors={colorSetting}
        borderColor={{ theme: "background" }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        animate
        motionStiffness={90}
        motionDamping={15}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "# Recommendations",
          legendPosition: "middle",
          legendOffset: -50
        }}
        defs={[
          {
            id: "lines-pattern",
            type: "patternLines",
            spacing: 10,
            rotation: -140,
            lineWidth: 1,
            background: "inherit",
            color: "#ccc"
          },
          {
            id: "dots-pattern",
            type: "patternDots",
            size: 1,
            padding: 4,
            stagger: false,
            background: "inherit",
            color: "#ccc"
          }
        ]}
        fill={[
          {
            match: {
              id: "Not Started"
            },
            id: "lines-pattern"
          },
          {
            match: {
              id: "Implemented"
            },
            id: "dots-pattern"
          }
        ]}
        onClick={e => handleChartClick(e, "status", "year")}
      />
    </React.Fragment>
  );
};

StackedBarByYear.propTypes = {
  byYearAndStatus: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string.isRequired
    })
  ).isRequired,
  recommendationStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChartClick: PropTypes.func.isRequired,
  statusKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  graphTextColor: PropTypes.string
};

StackedBarByYear.defaultProps = {
  graphTextColor: null
};

export default StackedBarByYear;
