/** @jsx jsx */
/* eslint react/jsx-filename-extension: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { jsx } from "@emotion/core";
import {
  Container,
  Grid,
  Header,
  Menu,
  Icon,
  Button,
  Dropdown,
  Loader
} from "semantic-ui-react";
import getStyles from "./index.styles";
import StackedBarByDepartment from "./stacked-bar-by-department";
import StackedBarByAudit from "./stacked-bar-by-audit";
import StackedBarByYear from "./stacked-bar-by-year";
import RecommendationTable from "./recommendation-table";

import PieGeneric from "./pie";
import UpdateStatuses from "./update-statuses";
import {
  addPhaseColorsToPieData,
  addCategoryColorsToPieData
} from "./custom-graph-colors";

const request = require("request-promise");

const recommendationDashboardBucket =
  process.env.RECOMMENDATION_DASHBOARD_BUCKET;

class RecommendationDashboard extends Component {
  state = {
    byDepartmentAndStatus: [],
    byYearAndStatus: [],
    byStatus: [],
    byCategory: [],
    overallStats: [],
    statusKeys: [],
    recommendationsTable: [],
    recommendationsTableCopy: [],
    sortColumn: "",
    sortDirection: "",
    chartingDataIsLoading: true,
    filteredText: null,
    activeFilterPrimary: null,
    activeFilterSecondary: null,
    activeDashboardSection: 0,
    dashboardData: null,
    trackSavings: true,
    recommendationStatuses: [],
    recommendationCategories: [],
    recommendationColor: []
  };

  constructor(params) {
    super(params);
    this.getChartingData = this.getChartingData.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.resetTable = this.resetTable.bind(this);
    this.handleChartClick = this.handleChartClick.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { dashboardId } = match.params;
    if (dashboardId) {
      this.getChartingData(dashboardId);
      this.getStatus(dashboardId);
    }
  }

  async getStatus(customerId) {
    const updateStatuses = await UpdateStatuses.get(customerId);
    this.setState({ statusKeys: _.map(updateStatuses, "value") });
  }

  handleChartClick(event, filterPrimary, filterSecondary) {
    const { recommendationsTableCopy } = this.state;

    const filter1 =
      (event.id &&
        _.filter(recommendationsTableCopy, { [filterPrimary]: event.id })) ||
      recommendationsTableCopy;

    if (filterSecondary) {
      const filter2 =
        (event.indexValue &&
          _.filter(filter1, {
            [filterSecondary]: event.indexValue
          })) ||
        filter1;

      this.setState({
        recommendationsTable: filter2,
        filteredText: `${event.indexValue} and ${event.id}`,
        activeFilterPrimary: filterPrimary,
        activeFilterSecondary: filterSecondary || null
      });
    } else {
      this.setState({
        recommendationsTable: filter1,
        filteredText: `${event.id}`,
        activeFilterPrimary: filterPrimary
      });
    }
  }

  handleDashboardSectionClick(section) {
    this.setState({ activeDashboardSection: section });
  }

  resetTable() {
    const { recommendationsTableCopy } = this.state;
    this.setState({
      recommendationsTable: recommendationsTableCopy,
      filteredText: null,
      activeFilterPrimary: null,
      activeFilterSecondary: null
    });
  }

  handleSort(clickedColumn) {
    const { sortColumn, sortDirection, recommendationsTable } = this.state;

    if (sortColumn !== clickedColumn) {
      this.setState({
        sortColumn: clickedColumn,
        recommendationsTable: _.sortBy(recommendationsTable, [clickedColumn]),
        sortDirection: "ascending"
      });

      return;
    }

    this.setState({
      recommendationsTable: recommendationsTable.reverse(),
      sortDirection: sortDirection === "ascending" ? "descending" : "ascending"
    });
  }

  async getChartingData(dashboardId) {
    const requestUrl = `https://${recommendationDashboardBucket}.s3-us-west-2.amazonaws.com/${dashboardId}/current.json`;

    const params = {
      method: "GET",
      uri: requestUrl,
      json: true,
      headers: {
        "Cache-Control": "no-cache"
      }
    };

    const dashboardData = await request(params);

    console.log("dashboard data!", dashboardData);

    const {
      byDepartmentAndStatus,
      byYearAndStatus,
      byAuditAndStatus,
      byStatus,
      byCategory,
      overallStats,
      recommendationsTable
    } = dashboardData.open;

    console.log("overall stats!", overallStats);
    console.log("byCategory!", byCategory);

    const {
      trackSavings,
      recommendationStatuses = [],
      recommendationCategories = [],
      recommendationColor = []
    } = dashboardData;
    const topTwentyAudits = _.takeRight(byAuditAndStatus, 20);

    this.setState({
      dashboardData,
      byDepartmentAndStatus,
      byYearAndStatus,
      byAuditAndStatus: topTwentyAudits,
      byStatus,
      byCategory,
      overallStats,
      recommendationsTable,
      recommendationsTableCopy: recommendationsTable,
      chartingDataIsLoading: false,
      trackSavings,
      recommendationStatuses,
      recommendationCategories,
      recommendationColor
    });
  }

  filterRecommendations(status) {
    const { dashboardData } = this.state;

    const {
      byDepartmentAndStatus,
      byYearAndStatus,
      byAuditAndStatus,
      byStatus,
      byCategory,
      overallStats,
      recommendationsTable
    } =
      (status === "open" && dashboardData.open) ||
      (status === "closed" && dashboardData.closed) ||
      dashboardData.all;

    const sortedByDepartmentAndStatus = _.sortBy(
      byDepartmentAndStatus,
      "totalCount"
    );

    const sortedByAuditAndStatus = _(byAuditAndStatus)
      .sortBy("totalCount")
      .takeRight(20)
      .value();

    this.setState({
      byDepartmentAndStatus: sortedByDepartmentAndStatus,
      byYearAndStatus,
      byAuditAndStatus: sortedByAuditAndStatus,
      byStatus,
      byCategory,
      overallStats,
      recommendationsTable,
      recommendationsTableCopy: recommendationsTable
    });
  }

  render() {
    // Functiond for chart clicking interaction
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0
    });

    const {
      byDepartmentAndStatus,
      byYearAndStatus,
      byAuditAndStatus,
      byStatus,
      byCategory,
      overallStats,
      statusKeys,
      recommendationsTable,
      sortColumn,
      sortDirection,
      chartingDataIsLoading,
      filteredText,
      activeDashboardSection,
      activeFilterPrimary,
      activeFilterSecondary,
      trackSavings,
      recommendationStatuses,
      recommendationCategories,
      recommendationColor
    } = this.state;

    const { handleSort, resetTable, handleChartClick } = this;

    const paramsRecommendationTable = {
      recommendations: recommendationsTable,
      handleSort,
      sortColumn,
      sortDirection,
      chartingDataIsLoading,
      activeFilterPrimary,
      activeFilterSecondary
    };

    const primaryColorObject = _.find(recommendationColor, {
      name: "Primary Color"
    });
    const primaryColor = _.get(primaryColorObject, "primaryColor");
    const styles = getStyles(primaryColor);

    const graphTextColorObject = _.find(recommendationColor, {
      name: "Graph Text Color"
    });
    const graphTextColor = _.get(graphTextColorObject, "graphTextColor");

    const pieGraphColorData = (data, colors) => {
      const hasStatus = data.some(
        item => item && typeof item === "object" && "status" in item
      );

      if (hasStatus) {
        return addPhaseColorsToPieData(data, colors);
      }
      return addCategoryColorsToPieData(data, colors);
    };

    const pieDataForCategories = pieGraphColorData(
      byCategory,
      recommendationCategories
    );

    const pieDataForStatuses = pieGraphColorData(
      byStatus,
      recommendationStatuses
    );

    const filterOptions = [
      { key: "open", value: "open", text: "open recommendations" },
      { key: "closed", value: "closed", text: "closed recommendations" },
      { key: "all", value: "all", text: "open & closed recommendations" }
    ];

    return (
      <Container css={styles}>
        <Grid.Column columns={4}>
          <Grid.Row className="reco-filter">
            <Grid.Column width={16} textAlign="center">
              Showing all &nbsp;
              <Dropdown
                defaultValue="open"
                inline
                options={filterOptions}
                placeholder="choose below"
                onChange={(e, d) => this.filterRecommendations(d.value)}
              />
              <br />
              <a
                href="#"
                onClick={() => window.open(window.location.href, "_blank")}
              >
                View Full Screen &#128470;
              </a>
            </Grid.Column>
          </Grid.Row>

          {chartingDataIsLoading ? (
            <Loader active inline="centered">
              Loading
            </Loader>
          ) : (
            <>
              <Grid.Row className="reco-stats">
                <Grid.Column width={16}>
                  <Grid>
                    <Grid.Row centered>
                      {!trackSavings && <Grid.Column width={3} />}
                      <Grid.Column width={3} className="reco-stat">
                        <div className="reco-stat-body">
                          <h4>{overallStats.auditCount}</h4>
                          <h5>Audit Reports</h5>
                        </div>
                      </Grid.Column>
                      {overallStats.investigationCount > 0 && (
                        <Grid.Column width={3} className="reco-stat">
                          <div className="reco-stat-body">
                            <h4>{overallStats.investigationCount}</h4>
                            <h5>Investigations</h5>
                          </div>
                        </Grid.Column>
                      )}
                      <Grid.Column width={3} className="reco-stat">
                        <div className="reco-stat-body">
                          <h4>{overallStats.recommendationCount}</h4>
                          <h5>Recommendations</h5>
                        </div>
                      </Grid.Column>
                      {!trackSavings && <Grid.Column width={3} />}
                      {trackSavings && (
                        <React.Fragment>
                          <Grid.Column width={3} className="reco-stat">
                            <div className="reco-stat-body">
                              <h4>
                                {currencyFormatter.format(
                                  overallStats.recommendationExpectedBenefit ||
                                    0
                                )}
                              </h4>
                              <h5>Potential Benefit</h5>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={3}
                            className="reco-stat reco-suavé"
                          >
                            <div className="reco-stat-body">
                              <h4>
                                {currencyFormatter.format(
                                  overallStats.recommendationRealizedBenefit ||
                                    0
                                )}
                              </h4>
                              <h5>Realized Benefit</h5>
                            </div>
                          </Grid.Column>
                        </React.Fragment>
                      )}
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="center" className="reco-nav">
                <Grid.Column textAlign="center">
                  <Menu secondary color="grey">
                    <Menu.Item
                      className="reco-nav-item"
                      name="Summary"
                      active={activeDashboardSection === 0}
                      onClick={() => this.handleDashboardSectionClick(0)}
                    />
                    <Menu.Item
                      className="reco-nav-item"
                      name="By Department"
                      active={activeDashboardSection === 1}
                      onClick={() => this.handleDashboardSectionClick(1)}
                    />
                    <Menu.Item
                      className="reco-nav-item"
                      name="By Audit"
                      active={activeDashboardSection === 2}
                      onClick={() => this.handleDashboardSectionClick(2)}
                    />
                  </Menu>
                </Grid.Column>
              </Grid.Row>

              {activeDashboardSection === 0 && (
                <Grid className="reco-detailed-stats">
                  <Grid.Row className="reco-detailed-stats-feature">
                    <Grid.Column width={16}>
                      <div className="reco-detailed-stats-feature-chart">
                        <Header as="h5" textAlign="center" color="grey">
                          Recommendations Issued by Year
                        </Header>
                        <StackedBarByYear
                          {...{
                            byYearAndStatus,
                            statusKeys,
                            handleChartClick,
                            recommendationStatuses,
                            graphTextColor
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <div className="reco-detailed-stats-sub-visual">
                        <Header as="h5" textAlign="center" color="grey">
                          Recommendations by Category
                        </Header>
                        <PieGeneric
                          {...{
                            data: pieDataForCategories,
                            handleChartClick,
                            clickType: "category",
                            graphTextColor
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <div className="reco-detailed-stats-sub-visual">
                        <Header as="h5" textAlign="center" color="grey">
                          Recommendations by Status
                        </Header>
                        <PieGeneric
                          {...{
                            data: pieDataForStatuses,
                            handleChartClick,
                            clickType: "status",
                            graphTextColor
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {activeDashboardSection === 1 && (
                <Grid className="reco-detailed-stats">
                  <Grid.Row className="reco-detailed-stats-feature">
                    <Grid.Column width={16}>
                      <div className="reco-detailed-stats-feature-chart">
                        <Header as="h5" textAlign="center" color="grey">
                          Recommendations by Department and Status
                        </Header>
                        <StackedBarByDepartment
                          {...{
                            byDepartmentAndStatus,
                            statusKeys,
                            handleChartClick,
                            recommendationStatuses,
                            graphTextColor
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {activeDashboardSection === 2 && (
                <Grid className="reco-detailed-stats">
                  <Grid.Row className="reco-detailed-stats-feature">
                    <Grid.Column width={16}>
                      <div className="reco-detailed-stats-feature-chart">
                        <Header as="h5" textAlign="center" color="grey">
                          Recommendations Issued by Audit (Top 20)
                        </Header>
                        <StackedBarByAudit
                          {...{
                            byAuditAndStatus,
                            statusKeys,
                            handleChartClick,
                            recommendationStatuses,
                            graphTextColor
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              <Grid.Row width={8}>
                <Grid.Column width={10}>
                  <div className="reco-detailed-stats-header">
                    <Header as="h3" textAlign="left">
                      Recommendation List
                      <Header.Subheader>
                        This table will filter itself as you interact with the
                        charts above.
                      </Header.Subheader>
                    </Header>

                    {filteredText && (
                      <React.Fragment>
                        <br />
                        <br />
                        <span>
                          <strong>Filtered by: </strong>
                          {filteredText}
                          &nbsp;&nbsp;
                          <Button
                            size="mini"
                            compact
                            icon
                            labelPosition="left"
                            onClick={resetTable}
                          >
                            <Icon name="undo alternate" />
                            Reset Table
                          </Button>
                        </span>
                      </React.Fragment>
                    )}

                    <RecommendationTable {...paramsRecommendationTable} />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid.Column>
      </Container>
    );
  }
}

RecommendationDashboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      dashboardId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default RecommendationDashboard;
