/* @jsx jsx */
import { css } from "@emotion/core";

// const LIGHT_GREEN = "#D6DEDD";
const BORDER_RADIUS = ".7rem";
const LIGHT_GREY = "#f8f8f8";

const getStyles = customColor => {
  const primaryColor = customColor || "#5EB58F"; // color from settings or MINT_ALTOID_GREEN

  return css`
    .reco-filter {
      text-align: center;
      margin-bottom: 1em;
      font-weight: 120;
    }

    .reco-stats {
      background: ${LIGHT_GREY};
      border-radius: ${BORDER_RADIUS};
      padding: 2rem 2rem !important;
    }

    .reco-stat {
      text-align: left;
      font-size: 100%;

      .reco-stat-body {
        padding: 1rem;
        border-radius: ${BORDER_RADIUS};
        background: #fff;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
      }

      h4 {
        color: ${primaryColor};
        font-size: 2rem;
        margin: 0;
        padding: 0;
        font-weight: 200;
      }

      h5 {
        font-size: 1em;
        font-weight: 800;
        line-height: 1;
        text-transform: uppercase;
        margin: 0.6rem 0 0 0;
        padding: 0;
        color: #666;
      }
    }

    .reco-nav {
      margin: 3rem 10rem 1rem 10rem;
      text-align: center;

      .item.reco-nav-item {
        margin: 0 10px;
        border: 1px solid ${primaryColor};
        color: ${primaryColor};
        width: 30%;
        text-align: center;
        font-size: 1.2rem;
        display: inline-block;

        &.active,
        &:hover {
          border: none;
          color: #fff !important;
          background: ${primaryColor} !important;
          border: 1px solid ${primaryColor} !important;
        }
      }
    }

    .reco-detailed-stats {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .reco-detailed-stats-feature {
      background: ${LIGHT_GREY};
      padding: 2rem 0 4rem 0 !important;
      margin-top: 2rem;
    }

    .reco-detailed-stats-feature-chart {
      height: 350px;
    }

    .reco-detailed-stats-sub-visual {
      height: 300px;
      margin: 2rem 0;
    }

    .recommendations-table {
      margin-top: 2em;
    }

    .reco-detailed-stats-header {
      margin: 2rem 0;

      h3 {
        color: ${primaryColor} !important;
        margin: 0 !important;
      }
    }
  `;
};

export default getStyles;
