import React from "react";
import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";

const PieGeneric = ({ data, handleChartClick, clickType, graphTextColor }) => {
  // @nivo/pie v 0.59.1 does not have a ResponsivePie parameter to change only the pie slice inner text color,
  // so I had to add a theme for all text that contained my custom color (graphTextColor) and then set the radialLabelsTextColor="black"
  const theme = {
    labels: {
      text: {
        fill: graphTextColor
      }
    }
  };
  return (
    <React.Fragment>
      <ResponsivePie
        data={data}
        radialLabelsTextColor="black"
        theme={theme}
        margin={{ top: 50, right: 30, bottom: 40, left: 30 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        borderWidth={1}
        colors={d => d.graphColor}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        animate
        motionStiffness={90}
        motionDamping={15}
        enableRadialLabels
        defs={[
          {
            id: "lines-pattern",
            type: "patternLines",
            spacing: 10,
            rotation: -110,
            lineWidth: 1,
            background: "inherit",
            color: "#ccc"
          },
          {
            id: "dots-pattern",
            type: "patternDots",
            size: 1,
            padding: 4,
            stagger: false,
            background: "inherit",
            color: "#ccc"
          }
        ]}
        fill={[
          {
            match: {
              id: "Not Started"
            },
            id: "dots-pattern"
          }
        ]}
        onClick={e => handleChartClick(e, clickType)}
      />
    </React.Fragment>
  );
};

PieGeneric.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  handleChartClick: PropTypes.func.isRequired,
  clickType: PropTypes.string,
  graphTextColor: PropTypes.string
};

PieGeneric.defaultProps = {
  clickType: "",
  graphTextColor: null
};

export default PieGeneric;
